#loginForm{
    background-color: white;
    max-width: 30%;
    border-radius: 10px;
    margin: auto;
    padding:50px;
}

#loginPage{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}